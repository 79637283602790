const errors = {
	REQUEST_AUTHORIZATION: ($lang) => ({
		title: $lang('site|shop', 'La transacción no fue autorizada por el banco emisor de la tarjeta'),
		text: $lang(
			'site|shop',
			'Comunicate con el banco emisor de tu tarjeta y solicitá la autorización de la transacción. Luego, volvé a intentar.'
		),
	}),
	CVV_SECURITY_ERROR: ($lang) => ({
		title: $lang(
			'site|shop',
			'La transacción fue rechazada por el banco emisor de la tarjeta porque se ha ingresado el código de seguridad, repetidas veces, de forma errónea'
		),
		text: $lang('site|shop', 'Comunicate con el banco emisor de tu tarjeta para resolver el problema.'),
	}),
	INSTALLMENTS_ERROR: ($lang) => ({
		title: $lang('site|shop', 'Error relacionado con la cantidad de cuotas'),
		text: $lang(
			'site|shop',
			'El procesador de pagos informa que la tarjeta no puede ser usada para pagos en cuotas o que se ha excedido la cantidad máxima de cuotas permitidas'
		),
	}),
	EXPIRATION_ERROR: ($lang) => ({
		title: $lang('site|shop', 'El vencimiento de tu tarjeta ha expirado'),
	}),
	AMOUNT_LIMIT_ERROR: ($lang) => ({
		title: $lang('site|shop', 'El monto de la operación excede tu límite máximo permitido'),
		text: $lang('site|shop', 'Realiza una nueva compra con un monto menor.'),
	}),
	INSUFFICIENT_FUNDS_ERROR: ($lang) => ({
		title: $lang('site|shop', 'Transaccion rechazada por fondos insuficientes'),
		text: $lang(
			'site|shop',
			'El procesador de pagos nos informa que no tienes fondos suficientes en tu tarjeta. Puedes intentar con otra Tarjeta de Crédito/Débito.'
		),
	}),
	TERMINAL_ERROR: ($lang) => ({
		title: $lang('site|shop', 'El sistema de pago no está funcionando momentáneamente'),
		text: $lang('site|shop', 'Espera unos minutos y vuelve a intentarlo.'),
	}),
	DUPLICATION_ERROR: ($lang) => ({
		title: $lang(
			'site|shop',
			'La transacción fue rechazada al haber sido considerada una duplicación de otra transacción hecha recientemente'
		),
		text: $lang(
			'site|shop',
			'Comunicate con el emisor de tu tarjeta o revisa tu Home Banking para verificar si ya habías realizado el pago.'
		),
	}),
	PAYMENT_REQUEST_ERROR: ($lang) => ({
		title: $lang('site|shop', 'Ha ocurrido un error al intentar procesar la transacción'),
		text: $lang(
			'site|shop',
			'Te pedimos disculpas por las molestias ocasionados. Por favor, vuelve a intentar en unos minutos.'
		),
	}),
	DEFAULT_ERROR: ($lang) => ({
		title: $lang('site|shop', 'Lo sentimos, tu pago fue rechazado'),
		text: $lang(
			'site|shop',
			'El procesador de pagos no especificó el motivo del rechazo.\n\nPara empezar, revisa que los datos ingresados de la tarjeta sean correctos y vuelve a intentarlo.\n\nDe lo contrario, comunicate con la entidad o banco emisor de tu tarjeta.'
		),
	}),
	VALIDATION_ERROR: ($lang) => ({
		title: $lang('site|shop', 'Ha ocurrido un error al validar los datos ingresados'),
		text: $lang('site|shop', 'Revisa los datos del formulario e intenta realizar el pago nuevamente'),
	}),
	INVALID_PLAN: ($lang) => ({
		title: $lang(
			'site|shop',
			'El plan de cuotas seleccionado no está vigente o ha cambiado sus costos de financiación'
		),
		text: $lang('site|shop', 'Por favor selecciona nuevamente el plan de cuotas para realizar el pago'),
	}),
}

export default function getRejectionError(rejectionCode, $lang) {
	return errors[rejectionCode] ? errors[rejectionCode]($lang) : errors.DEFAULT_ERROR($lang)
}
