<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		payOrder() {
			this.loading = true
			this.$window.location = this.$router.resolve({
				name: 'creditCardPayment',
				params: { id: this.order.id },
			}).href
		},
	},
}
</script>

<template>
	<div>
		<div
			class="font-2"
			v-html="
				$lang(
					'<b>Si aún no has realizado el pago</b> o quieres volver a intentarlo, <b>haz click en el siguiente botón</b> y serás redirigido a la pantalla de pago.'
				)
			"
		></div>
		<div class="py-5">
			<Button color="success" :loading="loading" @click="payOrder">
				{{ 'Pagar con Tarjeta' | lang }}
			</Button>
		</div>
		<div
			class="font-1"
			v-html="
				$lang(
					'Te solicitamos que <b>realices el pago dentro de las 72 hs.</b> de haber confirmado tu compra. Luego de ese plazo tu orden será cancelada.'
				)
			"
		></div>
	</div>
</template>
