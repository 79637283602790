<script>
export default {
	lang: 'shop',
	data() {
		return {
			logo: this.$srv('CreditCardAppBar.logo'),
			backColor: this.$srv('CreditCardAppBar.backColor'),
			textColor: this.$srv('CreditCardAppBar.textColor', 'link'),
		}
	},
	methods: {
		goToOrderDetail() {
			this.$router.push({
				name: 'user.order-detail',
				params: { id: this.$route.params.id },
			})
		},
	},
	mounted() {
		this.$vuetify.breakpoint.smAndDown && this.$window.toggleChat?.(false)
	},
	beforeDestroy() {
		this.$vuetify.breakpoint.smAndDown && this.$window.toggleChat?.(true)
	},
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<div>
		<div :style="{ 'background-color': backColor, 'z-index': 1 }" class="py-4 elevation-4">
			<v-container class="d-flex align-center pa-0">
				<Media :src="logo" class="mr-2" />
				<v-spacer />
				<Button text :color="textColor" small @click="goToOrderDetail">
					{{ 'Ver Orden' | lang }}
				</Button>
			</v-container>
		</div>
		<v-main class="grey lighten-3 pt-2">
			<router-view />
		</v-main>
	</div>
</template>
